import { nodeName } from "jquery";

class Alert {
  constructor({ selector, cookieName: companyName }) {
    this.selector = selector;

    if (!this.selector) { return null; }
    this.cookieName = `_${companyName}_alert`;

    this.alertBanner = document.querySelector(this.selector);
    this.startAlertBanner();
  }

  startAlertBanner() {
    if (this.alertBanner) {
      const alertVersion = this.alertBanner.getAttribute('data-alert-version');
      const alertCloseButton = this.alertBanner.querySelector('[data-alert-close]');
      const cookieDurationInSeconds = (60 * 60 * 24 * 3);

      alertCloseButton.addEventListener('click', () => {
        this.dismissAlert(alertVersion, cookieDurationInSeconds);
        document.body.classList.remove('has-alert-message');
      });

      if (!this.readCookie() || (this.readCookie() !== alertVersion)) {
        this.alertBanner.removeAttribute('style'); // Fix IE11 bug https://stackoverflow.com/questions/39851544/assignment-to-read-only-properties-is-not-allowed-in-strict-mode-ie11
        document.body.classList.add('has-alert-message');
      }
    }
  }

  dismissAlert = (alertVersion, cookieDurationInSeconds) => {
    this.setCookie(alertVersion, cookieDurationInSeconds);
    this.alertBanner.setAttribute('style', 'display: none;');
  }

  setCookie(value, secondsUntilExpire) {
    const date = new Date();
    date.setTime(date.getTime() + (secondsUntilExpire * 1000));
    const expires = `; expires=${date.toGMTString()}`;

    let cookieStr = `${escape(this.cookieName)}=${escape(value)}`;
    cookieStr += `${expires};`;
    if (location.protocol === 'https:') {
      cookieStr += ' Secure;';
    }
    cookieStr += ' path=/';
    document.cookie = cookieStr;
  }

  readCookie() {
    const nameEQ = `${escape(this.cookieName)}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return unescape(c.substring(nameEQ.length, c.length));
    }
    return null;
  }
}

export default Alert;
