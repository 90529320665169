class BasketIconAnimator {
  constructor({ wrapperSelector, numberSelector }) {
    this.basketIconWrapper = document.querySelectorAll(wrapperSelector);
    this.basketIconNumber = document.querySelectorAll(numberSelector);
  }

  triggerUpdate(int) {
    this.basketIconNumber.forEach((number) => {
      number.innerText = int;
    });

    this.basketIconWrapper.forEach((wrapper) => {
      if (int > 0) {
        if (!wrapper.classList.contains('page-header__basket--populated')) {
          wrapper.classList.add('page-header__basket--populated');
        }
        wrapper.classList.add('page-header__basket--animated');
        setTimeout(() => {
          wrapper.classList.remove('page-header__basket--animated');
        }, 900);
      } else {
        wrapper.classList.remove('page-header__basket--populated');
      }
    });
  }
}

export default BasketIconAnimator;
