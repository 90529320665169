class FocusOutlines {
  constructor() {
    window.addEventListener(
      'keydown',
      FocusOutlines.handleFirstTab,
      { passive: true },
    );
  }

  static handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing');
      window.removeEventListener(
        'keydown',
        FocusOutlines.handleFirstTab,
      );
      window.addEventListener(
        'mousedown',
        FocusOutlines.handleMouseDownOnce,
        { passive: true },
      );
    }
  }

  static handleMouseDownOnce() {
    document.body.classList.remove('user-is-tabbing');
    window.removeEventListener(
      'mousedown',
      FocusOutlines.handleMouseDownOnce,
    );
    window.addEventListener(
      'keydown',
      FocusOutlines.handleFirstTab,
      { passive: true },
    );
  }
}

export default FocusOutlines;
