import Glide from '@glidejs/glide';

class HeroSlider {
  constructor({ selector }) {
    this.selector = document.querySelector(selector);

    if (!this.selector) { return null; }

    const slides = this.selector.querySelectorAll('.glide__slide');

    if (slides.length > 1) {
      HeroSlider.mountSlider(this.selector);
    } else { // There is one slide or less
      // Add active class to the only slide so the caption animation still works
      let onlyChildSlide = document.querySelector('.glide__slide');
      let arrowNav = this.selector.querySelector('[data-glide-el="controls"]');

      if (arrowNav) {
        arrowNav.style.display = 'none';
      }

      setTimeout(() => {
        onlyChildSlide.classList.add('glide__slide--active');
      }, 300);
    }
  }

  static mountSlider(selector) {
    if (!selector) { return; }

    new Glide(selector, {
      type: 'slider',
      gap: 0,
      animationDuration: 1000,
      autoplay: 5500,
      perView: 1,
      autoheight: true,
    }).mount();
  }
}

export default HeroSlider;
