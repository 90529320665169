import Helpers from '../lib/helpers';

class CollapsibleSection {
  constructor({ selector }) {
    // Check for existence of selector
    this.selector = document.querySelectorAll(selector);
    if (!this.selector) { return null; }

    // Run if selector found on page
    CollapsibleSection.toggleCollapsibleSections();
  }

  static toggleCollapsibleSections() {
    const headings = document.querySelectorAll('[data-panel-collapsible-title]');

    Helpers.forEach(headings, (heading) => {
      const btn = heading.querySelector('[data-panel-collapsible-btn]');
      const target = heading.nextElementSibling;
      const parentPanel = heading.parentElement;

      btn.onclick = () => {
        // Toggle data-state on heading container div
        heading.setAttribute('data-state', heading.getAttribute('data-state') === 'open' ? 'closed' : 'open');
        // Toggle data-state on panel container div
        parentPanel.setAttribute('data-state', parentPanel.getAttribute('data-state') === 'open' ? 'closed' : 'open');

        // Toggle aria-expanded on heading
        const expanded = btn.getAttribute('aria-expanded') === 'true' || false;
        btn.setAttribute('aria-expanded', !expanded);
        target.hidden = expanded;
      };
    });
  }
}

export default CollapsibleSection;
