import Helpers from '../lib/helpers';
import BasketIconAnimator from './basket-icon-animator';

class BasketButtonAnimator {
  constructor({ selector }) {
    const forms = document.querySelectorAll(selector);

    if (forms.length <= 0) { return; }

    Helpers.forEach(forms, (form) => {
      form.addEventListener('ajax:before', (event) => {
        const button = event.currentTarget.querySelector('button');
        const buttonText = button.querySelector('[data-basket-button-text]');

        buttonText.innerHTML = button.getAttribute('data-js-text-spinner');
        button.classList.remove('added');
        button.classList.add('loading');
        button.querySelector('svg use').setAttribute('xlink:href', '#svg--loader');
      });

      form.addEventListener('ajax:success', (event) => {
        const button = event.currentTarget.querySelector('button');
        const buttonText = button.querySelector('[data-basket-button-text]');
        const itemsCountObj = event.detail[0];

        buttonText.innerHTML = button.getAttribute('data-js-text-complete');
        button.classList.remove('loading');
        button.classList.add('added');
        button.disabled = true;
        button.querySelector('svg use').setAttribute('xlink:href', '#svg--tick');

        if (typeof itemsCountObj === 'object' && itemsCountObj !== null) {
          if ('itemsCount' in itemsCountObj) {
            const b = new BasketIconAnimator({
              wrapperSelector: '[data-header-basket]',
              numberSelector: '[data-header-basket-quantity]'
            });
            b.triggerUpdate(itemsCountObj.itemsCount);
          }
        }

        setTimeout(() => {
          button.disabled = false;
          button.classList.remove('added');
          button.querySelector('svg use').setAttribute('xlink:href', '#svg--basket');
          buttonText.innerHTML = button.getAttribute('data-js-text-initial');
        }, 1500);
      });
    });
  }
}

export default BasketButtonAnimator;
