import Helpers from '../lib/helpers';

class EqualHeightCells {
  /**
  * Make two thirds / one-third grid images the same height
  */

  constructor({ selector, breakpoint }) {
    this.selector = selector;
    this.bp = breakpoint; // Breakpoint width at which the grid is created

    if (!this.selector) { return null; }

    // Only targets 2/3rds grids which have images in both cells
    this.targetedGrids = document.querySelectorAll(this.selector);

    if (!this.targetedGrids) { return null; }

    // check viewport width immediately
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    // run immediately if above breakpoint
    if (this.targetedGrids) {
      if (vw > this.bp) {
        // Wait for images to load before resizing cells
        setTimeout(() => {
          this.equalGridCellHeights();
        }, 500);
        // wait again just incase we have a slow page load
        setTimeout(() => {
          this.equalGridCellHeights();
        }, 2000);
        setTimeout(() => {
          this.equalGridCellHeights();
        }, 5000);
        setTimeout(() => {
          this.equalGridCellHeights();
        }, 10000);
      }
    }

    // and again on page resize
    window.addEventListener('resize', this.equalHeightsOnResize, { passive: true });
  }

  equalGridCellHeights() {
    Helpers.forEach(this.targetedGrids, (el, i) => {
      let thirdCell = el.querySelector('[data-grid-thirds-cell]');
      let twoThirdsCell = el.querySelector('[data-grid-two-thirds-cell]');
      let thirdHeight = thirdCell.getBoundingClientRect().height;
      // Make the 2-thirds picture element same height as the one-thirds next to it
      twoThirdsCell.style.height = `${Math.round(thirdHeight)}px`;
    });
  }

  removeHeightFromCell() {
    Helpers.forEach(this.targetedGrids, (el, i) => {
      let twoThirdsCell = el.querySelector('[data-grid-two-thirds-cell]');
      // Remove inline styles from the element
      twoThirdsCell.removeAttribute('style');
    });
  }

  equalHeightsOnResize = Helpers.debounce(() => {
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    // run if above breakpoint
    if (vw > this.bp) {
      this.equalGridCellHeights();
    } else {
      this.removeHeightFromCell();
    }
  }, 200);
}

export default EqualHeightCells;
