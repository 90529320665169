import Glide from '@glidejs/glide';
import Helpers from '../lib/helpers';

class GallerySlider {
  constructor({
    selector,
    navBulletsArray = [],
    navArrowsArray = [],
  }) {
    this.selector = document.querySelectorAll(selector);

    if (!this.selector) { return null; }

    this.navBulletsArray = navBulletsArray;
    this.navArrowsArray = navArrowsArray;

    this.gallerySlider();
  }

  gallerySlider() {
    const galleries = this.selector;

    Helpers.forEach(galleries, (el, index) => {
      // Add nav bullets in before we mount so they have event listeners attached
      const glideTrack = el.querySelector('[data-glide-el="track"]');
      const bulletNav = el.querySelector('[data-glide-el="controls[nav]"]');
      const arrowNav = el.querySelector('[data-glide-el="controls"]');

      // Send nav bullets to array so can access them later
      this.navBulletsArray.push(bulletNav);

      // Send nav arrows to array so can access them later
      this.navArrowsArray.push(arrowNav);

      // Add a nav bullet inside the container for each .glide__slide
      for (let i = 0; i < glideTrack.firstElementChild.childElementCount; i += 1) {
        bulletNav.innerHTML
          += `<button class="glide__bullet" data-glide-dir="=${[i]}"><span></span></button>`;
      }

      new Glide(el, {
        type: 'carousel',
        gap: 0,
        animationDuration: 1000,
        autoplay: false,
        perView: 1,
        autoheight: true,
      }).mount();

      // Immmediately move the arrows / bullets
      this.calculateNewArrowPosition(el, index);

      // And run again on resize
      window.addEventListener('resize', () => {
        this.calculateNewArrowPosition(el, index);
      }, { passive: true });
    }, this);
  }

  calculateNewArrowPosition(el, index) {
    // Select the bullet nav container
    const currentBulletNav = this.navBulletsArray[index];

    // Get it's height
    const bulletNavHeight = currentBulletNav.getBoundingClientRect().height;

    // Center the arrows to the image
    const arrows = this.navArrowsArray[index];
    const distancePx = `${bulletNavHeight}px`;
    arrows.style.bottom = distancePx;
  }
}

export default GallerySlider;
