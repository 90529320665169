import Console from './console';

/* eslint max-len: ["error", { "code": 120 }] */

class Navigation {
  constructor({
    headerObject, // we only need this to get the current viewportSituation
    rootList,
    childNavToggleSelector,
    translationForAriaLabelShow,
    translationForAriaLabelHide,
  }) {
    this.headerObject = headerObject;
    this.rootList = rootList;
    this.childNavToggleSelector = childNavToggleSelector;
    this.translationForAriaLabelShow = translationForAriaLabelShow;
    this.translationForAriaLabelHide = translationForAriaLabelHide;
    if (!this.headerObject) { Console.error('headerObject not supplied'); return null; }
    if (!this.rootList) { Console.error('rootList not supplied'); return null; }
    if (!this.childNavToggleSelector) { Console.error('childNavToggleSelector not supplied'); return null; }
    if (!this.translationForAriaLabelShow) { Console.error('translationForAriaLabelShow not supplied'); return null; }
    if (!this.translationForAriaLabelHide) { Console.error('translationForAriaLabelHide not supplied'); return null; }

    this.touchDevice = false;
    this.initialiseList(this.rootList);
    this.initialiseTouchEvents();
    this.initialiseEscapeKey();
  }

  initialiseList(list, level = 1) {
    this.initialiseListItemToggleClicks(list);

    if (level === 1) {
      this.initialiseListItemAutoRevealOnMouseEnter(list);
    }

    this.initialiseAnySubLists(list, level);
  }

  initialiseAnySubLists(currentList, currentLevel) {
    currentList.querySelectorAll(':scope > li').forEach((listItem) => {
      const subList = listItem.querySelector(':scope > ul');
      if (subList) {
        Console.log('about to initialise subList:', subList);
        this.initialiseList(subList, currentLevel + 1);
      }
    });
  }

  // Note:  With the current CSS - this is only used on Mobile, or on Desktop
  // with mouse (but only above level1), or desktop with touch device.
  initialiseListItemToggleClicks(list) {
    list.querySelectorAll(`:scope > li > ${this.childNavToggleSelector}`).forEach((listItemToggle) => {
      listItemToggle.addEventListener(
        'click',
        () => {
          Console.log('clicked toggle');
          if (listItemToggle.getAttribute('aria-expanded') === 'true') {
            this.hideListItemSubList(listItemToggle.parentElement);
          } else {
            if (this.headerObject.viewportSituationDesktop()) {
              this.collapseAnyExpandedNavOfOtherParents(listItemToggle.parentElement);
            }
            this.showListItemSubList(listItemToggle.parentElement);
          }
        },
        { passive: true },
      );
    });
  }

  initialiseListItemAutoRevealOnMouseEnter(list) {
    list.querySelectorAll(':scope > li').forEach((listItem) => {
      listItem.addEventListener(
        'mouseenter',
        (event) => this.handleMouseEnteredListItem(event),
      );
      listItem.addEventListener(
        'mouseleave',
        (event) => this.handleMouseLeftListItem(event),
      );
    });
  }

  // Note this is currently only in play for Level 1, and wide (desktop).
  // This method is currently used to automatically expand (display) the Level 1
  // nav on mouse enter (when not using touch device).
  handleMouseEnteredListItem(event) {
    Console.log('mouse entered');
    if (this.headerObject.viewportSituationMobile()
         || this.touchDevice) {
      return;
    }

    const listItem = event.target;
    this.showListItemSubList(listItem);
  }

  // Note this is currently only in play for Level 1, and wide (desktop).
  // This method is currently used to automatically collapse (hide) the Level 1
  // nav on mouse leave (when not using touch device).
  handleMouseLeftListItem(event) {
    if (this.headerObject.viewportSituationMobile()
        || this.touchDevice) {
      return;
    }

    const listItem = event.target;
    this.hideListItemSubList(listItem);
  }

  showListItemSubList(listItem) {
    Console.log('Showing sub list (Aria expanding)');

    const link = listItem.querySelector('a');
    link.setAttribute('aria-expanded', 'true');

    const toggle = listItem.querySelector(`:scope > ${this.childNavToggleSelector}`);
    if (toggle) {
      toggle.setAttribute('aria-expanded', 'true'); // this visually flips the arrow with current css
      toggle.setAttribute('aria-label', this.translationForAriaLabelHide);
    }

    const subList = listItem.querySelector(':scope > ul');
    if (subList) {
      // set whatever is required
    }
  }

  hideListItemSubList(listItem) {
    const link = listItem.querySelector('a');

    link.setAttribute('aria-expanded', 'false');
    link.blur();

    const toggle = listItem.querySelector(`:scope > ${this.childNavToggleSelector}`);
    if (toggle) {
      toggle.setAttribute('aria-expanded', 'false'); // this visually flips the arrow with current css
      toggle.setAttribute('aria-label', this.translationForAriaLabelShow);
    }

    const subList = listItem.querySelector(':scope > ul');
    if (subList) {
      // set whatever is required
    }
  }

  initialiseTouchEvents() {
    this.touchHandler = this.handleInitialTouchDetected.bind(this);
    document.addEventListener(
      'touchstart',
      this.touchHandler,
      { passive: true },
    );
  }

  handleInitialTouchDetected() {
    Console.log('Handling initial touch detection');
    this.touchDevice = true;

    this.rootList.querySelectorAll(this.childNavToggleSelector).forEach((el) => {
      const toggle = el;
      toggle.style.pointerEvents = 'auto';
    });

    document.removeEventListener(
      'touchstart',
      this.touchHandler,
    );

    document.addEventListener(
      'touchstart',
      (event) => this.handleSubsequentTouchDetected(event),
      { passive: true },
    );
  }

  handleSubsequentTouchDetected(event) {
    Console.log('Handling subsequent touch');
    const closestNav = event.target.closest('nav');
    if (closestNav
         && (closestNav.querySelector('ul') === this.rootList)
    ) {
      return;
    }

    if (this.headerObject.viewportSituationDesktop()) {
      this.collapseAnyExpandedNav();
    }
  }

  collapseAnyExpandedNav(exceptThoseWithSameRootListItemAs = null) {
    this.rootList.querySelectorAll(`${this.childNavToggleSelector}[aria-expanded=true]`).forEach((toggle) => {
      if (!Navigation.elementsHaveSameRootListItem(toggle, exceptThoseWithSameRootListItemAs)) {
        Console.log(toggle, 'is being made aria-expanded=false');
        toggle.setAttribute('aria-expanded', 'false');
      }
    });

    this.rootList.querySelectorAll('li > a[aria-expanded=true]').forEach((a) => {
      if (!Navigation.elementsHaveSameRootListItem(a, exceptThoseWithSameRootListItemAs)) {
        Console.log(a, 'is being made aria-expanded=false');
        a.setAttribute('aria-expanded', 'false');
      }
    });
  }

  collapseAnyExpandedNavOfOtherParents(currentListItem) {
    this.collapseAnyExpandedNav(currentListItem);
  }

  static elementsHaveSameRootListItem(elementA, elementB) {
    const rootListItemSelector = 'nav > ul > li';

    if (!elementA || !elementB) {
      return false;
    }

    return (elementA.closest(rootListItemSelector) === elementB.closest(rootListItemSelector));
  }

  initialiseEscapeKey() {
    Console.log('Initialising escape key');
    document.addEventListener(
      'keydown',
      (event) => {
        const theEvent = event || window.event;
        let isEscape = false;
        if ('key' in theEvent) {
          isEscape = theEvent.key === 'Escape' || theEvent.key === 'Esc';
        } else {
          isEscape = theEvent.keyCode === 27;
        }
        if (isEscape) {
          this.collapseAnyExpandedNav();
        }
      },
      { passive: true },
    );
  }
}

export default Navigation;
