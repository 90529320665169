import Console from './console';

/* eslint max-len: ["error", { "code": 130 }] */

class MenuButton {
  constructor({
    headerObject,
    menuToggle,
    slidingNav,
  }) {
    this.headerObject = headerObject;
    this.menuToggle = menuToggle;
    this.slidingNav = slidingNav;
    if (!this.headerObject) { Console.error('headerObject not supplied'); return null; }
    if (!this.menuToggle) { Console.error('menuToggle not supplied'); return null; }
    if (typeof this.slidingNav !== 'boolean') { Console.error('slidingNav incorrect'); return null; }

    this.navSlideHideTimer = undefined; // For a setTimeout later

    this.initialiseMenuButtonClickActions();
  }

  initialiseMenuButtonClickActions() {
    this.menuToggle.addEventListener('click', (e) => {
      if (this.headerObject.menuVisibleOnMobile) {
        this.hideMenuOnMobile();
      } else {
        this.showMenuOnMobile();
      }
      e.preventDefault();
    });
  }

  // Only ever gets called when burger menu toggle is clicked (event listener).
  // This is related to user interactions.
  showMenuOnMobile() {
    Console.log('showMenuOnMobile');
    this.headerObject.menuVisibleOnMobile = true;

    document.querySelector('html').classList.add('has-primary-nav'); // this class provides the css animation
    this.menuToggle.setAttribute('aria-expanded', 'true');

    clearTimeout(this.navSlideHideTimer); // cancel timer (if present) which will try to hide the nav

    this.headerObject.showTabbableElements();
    this.headerObject.trapTabbing();

    if (this.slidingNav) {
      this.headerObject.addSlidingNavOverlay();
    }
  }

  //
  // **  This gets called by a few places  ************  //
  // Called by both viewport width conditions/resizes and user interactions
  hideMenuOnMobile() {
    Console.log('hideMenuOnMobile');
    this.headerObject.menuVisibleOnMobile = false;

    document.querySelector('html').classList.remove('has-primary-nav'); // this class removal provides the css animation
    this.menuToggle.setAttribute('aria-expanded', 'false');

    if (this.slidingNav) {
      // We must only disable (hide) these elements once the css animation has
      // completed, so doesnt look bad.
      this.navSlideHideTimer = setTimeout(() => {
        this.headerObject.hideTabbableElements();
      }, 500);
    } else {
      this.headerObject.hideTabbableElements();
    }
    this.headerObject.unTrapTabbing();

    if (this.slidingNav) {
      this.headerObject.removeSlidingNavOverlay();
    }
  }
}

export default MenuButton;
