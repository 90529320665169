/* eslint no-console: "off", no-unused-vars: "off" */

class Console {
  static log(...args) {
    // console.log(...args);
  }

  static error(...args) {
    // console.error(...args);
  }
}

export default Console;
