import Helpers from '../lib/helpers';
import BasketIconAnimator from './basket-icon-animator';

class BasketPageUpdateQuantity {
  constructor({ selector }) {
    const forms = document.querySelectorAll(selector);

    if (forms.length <= 0) { return; }

    Helpers.forEach(forms, (form) => {
      form.addEventListener('ajax:success', (event) => {
        const itemsCountObj = event.detail[0];
        const row = form.closest('[data-basket-row]');
        Helpers.addClass(row, 'basket__row--updated');
        if (typeof itemsCountObj === 'object' && itemsCountObj !== null) {
          if ('itemsCount' in itemsCountObj) {
            const b = new BasketIconAnimator({
              wrapperSelector: '[data-header-basket]',
              numberSelector: '[data-header-basket-quantity]',
            });
            b.triggerUpdate(itemsCountObj.itemsCount);
          }
        }
        setTimeout(() => {
          Helpers.removeClass(row, 'basket__row--updated');
        }, 400);
      });
    });
  }
}

export default BasketPageUpdateQuantity;
