class AccountApplication {
  constructor({ selector, children }) {
    this.selector = selector;
    this.children = children;
    this.allElement = document.querySelector(this.selector);
    if (!this.allElement) return;
    this.childElements = document.querySelectorAll(this.children);
    if (!this.childElements) return;

    this.changeSelections = this.changeSelections.bind(this);
    this.attachSelections();
  }

  attachSelections() {
    this.allElement.addEventListener('change', this.changeSelections);
  }

  changeSelections() {
    this.childElements.forEach((element) => {
      element.checked = this.allElement.checked;
    });
  }
}

export default AccountApplication;
