import Helpers from '../lib/helpers';
import BasketIconAnimator from '../application/basket-icon-animator';

class RemoveBasketRowAnimator {
  constructor({ selector }) {
    const forms = document.querySelectorAll(selector);

    if (forms.length <= 0) { return; }

    Helpers.forEach(forms, (form) => {
      form.addEventListener('ajax:success', (event) => {
        // Find it's basket row parent
        const row = form.closest('[data-basket-row]');
        this.timeoutTimer += 1500;
        const itemsCountObj = event.detail[0];

        // Add .removing class to do opacity transition

        Helpers.addClass(row, 'basket__row--removing');

        // Add .removed class to remove row from display

        if (typeof itemsCountObj === 'object' && itemsCountObj !== null) {
          if ('itemsCount' in itemsCountObj) {
            const b = new BasketIconAnimator({
              wrapperSelector: '[data-header-basket]',
              numberSelector: '[data-header-basket-quantity]',
            });
            b.triggerUpdate(itemsCountObj.itemsCount);
          }
        }
        setTimeout(() => {
          Helpers.addClass(row, 'basket__row--removed');
        }, 500);
      });
    });
  }
}

export default RemoveBasketRowAnimator;
